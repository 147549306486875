body {
    margin: 0;
    padding: 0;
    overflow-x: hidden !important;
  }
  .bt_btn:hover{
    outline: none;
    border: none;
  }

   @font-face {
    font-family: "f1";
    src: url("../fonts/Inter-Regular.ttf");
  }
  @font-face {
    font-family: "f2";
    src: url("../fonts/Inter-Medium.ttf");
  }
  @font-face {
    font-family: "f3";
    src: url("../fonts/Inter-Bold.ttf");
  } 
  
  .w-90 {
    width: 90%;
    margin: auto;
  }
  .w-80 {
    width: 80%;
    margin: auto;
  }
  .nav_me img {
    width: 270px;
  }
  .navbar-bg {
    background: #f6f6f6;
  }
  .navbar-light .navbar-nav .nav-link {
    padding-right: 10px !important;
    padding-left: 10px !important;
    margin-right: 5px;
  }
  .navbar-light .navbar-nav .nav-link.active {
    background: #258df6;
    color: #fff !important;
  }
  .navbar-light .navbar-nav .nav-link:hover {
    background: #258df6;
    color: #fff !important;
  }
  .nav-bar .nav_a li a {
    font-family: "f2";
    font-size: 14px;
  }
  .navbar-light .navbar-toggler {
    color: rgb(0 0 0 / 0%) !important;
    border-color: rgb(0 0 0 0.1) !important;
  }
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: box-shadow 0.15s ease-in-out;
  }
  .qu_o {
    font-family: "f3";
    font-size: 14px !important;
    background-color: #258df6 !important;
  
    border-radius: 0 !important;
    border: none;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 21%);
  }
  
  /* Banner starts */
  
  .cont_ent h1 {
    font-family: "f3";
    color: #258df6;
    font-size: 30px;
    /* height: 47px; */
    overflow: hidden;
  }
  .cont_ent p {
    font-family: "f2";
    font-weight: 500;
    font-size: 17px;
    /* height: 50px; */
    overflow: hidden;
  }
  .ban_img img {
    width: 100%;
  }
  .cont_ent .bt_btn {
    color: #fff !important;
    background-color: #000;
    font-family: "f2";
    border-radius: 2px;
  }
  .cont_ent a {
    font-size: 16px;
  }
  /* banner ends */
  
  /* what can we do starts */
  .soft_ne h1 {
    font-family: "f3";
    font-size: 28px;
    border: 2px dashed;
    border-radius: 15px;
    padding: 10px 25px 10px 25px;
  }
  .wh_at {
    background-color: #f9f9f9;
    border-radius: 25px;
    padding: 50px;
    height: 320px;
  }
  .wh_at h2 {
    font-family: "f3";
    font-size: 23px;
  }
  .wh_at p {
    font-family: "f2";
    font-size: 15px;
    /* text-align: justify; */
  }
  .hov_eff {
    display: none;
  }
  .po_pu {
    height: auto;
  }
  .po_pu:hover .hov_eff {
    display: block;
  }
  
  .po_pu:hover {
    box-shadow: 0px 3px 15px rgb(0, 0, 0, 85%);
    padding: 20px;
    border-radius: 10px;
    background: #fff;
  }
  .hov_eff p {
    /* text-align: justify; */
    font-size: 12px;
  }
  .cir_le {
    position: relative;
  }
  .si_cir {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background: #f80;
    position: absolute;
    left: -180px;
    top: -170px;
    z-index: -1;
  }
  .se_onese {
    position: relative;
    overflow-x: hidden;
  }
  .si_sq {
    position: absolute;
    width: 150px;
    height: 150px;
    background: #be0200;
    right: -87px;
    top: 190px;
    border-radius: 20px;
    transform: rotate(314deg);
    z-index: -1;
  }
  
  /* what can we do end */
  
  /* S one starts */
  .te_xt h1 {
    font-family: "f3";
    font-size: 28px !important;
  }
  .te_xt h2 {
    font-family: "f2";
    font-size: 23px;
  }
  .te_xt p {
    font-family: "f2";
    font-size: 14px;
    text-align: justify;
  }
  .im_ge img {
    width: 100%;
  }
  .te_xt .bt_btn {
    background-color: #000;
    color: #fff !important;
    border-radius: 2px;
  }
  .te_xt a {
    font-size: 13px;
  }
  /* S one ends */
  .line_box {
    width: 20px;
    height: 20px;
    border: 1px solid #7e7e7e;
    position: relative;
    transform: rotate(45deg);
    margin: auto;
  }
  .lines_box {
    left: 0px;
    right: 0px;
    top: 3px;
    width: 12px;
    height: 12px;
    border: 1px solid #0000008f;
    position: absolute;
    transform: rotate(270deg);
    margin: auto;
  }
  .line_box:before {
    content: "";
    position: absolute;
    border-bottom: 2px solid #0000008f;
    top: 0;
    width: 102px;
    transform: rotate(-45deg);
  }
  .line_box::before {
    right: 100%;
    margin: 68px auto;
  }
  .line_box:after {
    content: "";
    position: absolute;
    border-bottom: 2px solid #0000008f;
    top: 0;
    width: 102px;
    transform: rotate(-45deg);
  }
  .line_box:after {
    left: 100%;
    margin: -50px auto;
  }
  /* our services starts */
  .cir_cle1 {
    height: 80px;
    width: 80px;
    border-radius: 100%;
    margin: auto;
  }
  .ii_ot {
    height: 80px;
    width: 80px;
    border: 2px solid #fff;
    border-radius: 100%;
    padding: 15px;
    margin: -5px;
  }
  .ii_ot img {
    width: 50px;
    height: 50px;
  }
  .cir_cle2 {
    height: 80px;
    width: 80px;
    border-radius: 100%;
    margin: auto;
  }
  .mob_ile {
    height: 80px;
    width: 80px;
    border: 2px solid #fff;
    border-radius: 100%;
    padding: 15px;
    margin: -5px;
  }
  .mob_ile img {
    width: 50px;
    height: 50px;
  }
  
  .cir_cle3 {
    height: 80px;
    width: 80px;
    border-radius: 100%;
    margin: auto;
  }
  .vr_ar {
    height: 80px;
    width: 80px;
    border: 2px solid #fff;
    border-radius: 100%;
    padding: 15px;
    margin: -5px;
  }
  .vr_ar img {
    width: 50px;
    height: 50px;
  }
  .ser_vic1 {
    height: 80px;
    width: 80px;
    background-color: #f80;
    border-radius: 100%;
    box-shadow: 5px 5px 3px rgb(0, 0, 0, 16%);
    margin: auto;
  }
  .cr_ms {
    height: 80px;
    width: 80px;
    border: 2px solid;
    border-radius: 100%;
    padding: 21px;
    margin: -5px;
  }
  .ser_vic2 {
    height: 80px;
    width: 80px;
    background-color: #248df6;
    border-radius: 100%;
    box-shadow: 5px 5px 3px rgb(0, 0, 0, 16%);
    margin: auto;
  }
  .fi_na {
    height: 80px;
    width: 80px;
    border: 2px solid;
    border-radius: 100%;
    padding: 21px;
    margin: -5px;
  }
  .ser_vic3 {
    height: 80px;
    width: 80px;
    background-color: #be0200;
    border-radius: 100%;
    box-shadow: 5px 5px 3px rgb(0, 0, 0, 16%);
    margin: auto;
  }
  .hr_ms {
    height: 80px;
    width: 80px;
    border: 2px solid;
    border-radius: 100%;
    padding: 21px;
    margin: -5px;
  }
  .ser_vic4 {
    height: 80px;
    width: 80px;
    background-color: #08b294;
    border-radius: 100%;
    box-shadow: 5px 5px 3px rgb(0, 0, 0, 16%);
    margin: auto;
  }
  .em_po {
    height: 80px;
    width: 80px;
    border: 2px solid;
    border-radius: 100%;
    padding: 21px;
    margin: -5px;
  }
  .ser_vic5 {
    height: 80px;
    width: 80px;
    background-color: #5f2ac4;
    border-radius: 100%;
    box-shadow: 5px 5px 3px rgb(0, 0, 0, 16%);
    margin: auto;
  }
  .re_po {
    height: 80px;
    width: 80px;
    border: 2px solid;
    border-radius: 100%;
    padding: 21px;
    margin: -5px;
  }
  .fi_head h1 {
    font-family: "f3";
    font-size: 28px;
  }
  .col_one {
    border-radius: 15px;
    padding: 15px;
    text-align: center !important;
    height: 315px;
    border: 1px solid #000;
    width: 95%;
    margin: auto;
  }
  .col_one img {
    width: 40px;
  }
  .owl-nav {
    display: none;
  }
  .owl-dots {
    display: none;
  }
  
  .col_one h5 {
    font-family: "f3";
    font-size: 15px;
    text-align: left;
    margin-top: 10px;
    color: #000 !important ;
    text-align: center !important;
    height: 35px;
    overflow: hidden;
  }
  .col_one p {
    font-family: "f1";
    font-size: 14px;
    text-align: justify;
  }
  .sr_vi h5 {
    font-family: "f3";
    font-size: 16px;
    text-align: left !important;
    margin-top: 10px;
    color: #fff !important ;
  }
  .sr_vi p {
    font-family: "f1";
    font-size: 14px;
  }
  .cap_tion h1 {
    font-family: "f3";
    font-size: 28px;
  }
  .cap_tion p {
    font-family: "f1";
    font-size: 14px;
    text-align: justify;
  
    height: 125px;
    overflow: hidden;
    margin-bottom: 46px;
  }
  .owl-carousel {
    display: block !important;
  }
  .ou_rs2 {
    margin-top: 30px;
  }
  .cir_le2 {
    position: relative;
  }
  .si_cir2 {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background: #248df6;
    position: absolute;
    left: -170px;
    top: -260px;
  }
  /* our services ends */
  
  /* customer review starts */
  /* .bor_tes{
      position: relative;
  } */
  .ou_head {
    margin-bottom: 55px;
  }
  .ou_head h1 {
    font-family: "f3";
    font-size: 28px;
  }
  .box_ba {
    background-color: #f80;
    border-radius: 15px;
    padding: 5px;
  }
  .box_bar {
    border-radius: 15px;
    border: 2px solid;
    margin-left: -15px;
    padding: 30px;
    margin-top: -15px;
    position: relative;
  }
  .box_bar h5 {
    text-align: center !important;
    color: #000 !important;
  }
  .box_bar p {
    color: #000;
    margin-top: 10px;
    /* height: 60px; */
    overflow: hidden;
  }
  .box_ba2 h5 {
    text-align: center !important;
    color: #fff !important;
  }
  .box_ba2 p {
    color: #fff;
    margin-top: 10px;
    /* height: 60px; */
    overflow: hidden;
  }
  .box_ba2 {
    background-color: #be0200;
    border-radius: 15px;
    padding: 5px;
  }
  .box_ba3 {
    background-color: #248df6;
    border-radius: 15px;
    padding: 5px;
  }
  
  .lo_ve h1 {
    font-family: "f3";
    font-size: 28px;
  }
  .lo_ve p {
    font-family: "f1";
    font-size: 15px;
    text-align: justify;
  }
  .lo_vecu p {
    font-family: "f1";
    font-size: 15px;
    text-align: justify;
    color: #fff;
  }
  .q_cirb {
    height: 65px;
    width: 65px;
    background-color: #fff;
    border-radius: 100%;
    padding: 17px;
    position: absolute;
    bottom: -30px;
    right: 20px;
  }
  .q_cirt {
    height: 65px;
    width: 65px;
    background-color: #fff;
    border-radius: 100%;
    padding: 17px;
    position: absolute;
    top: -40px;
  }
  /* customer review ends */
  
  /* enquiry form starts  */
  .enq_img img {
    /* height: 50%; */
    width: 88%;
  }
  .fo_rm h1 {
    font-family: "f3";
    font-size: 28px;
  }
  .fo_rm p {
    font-family: "f1";
    font-size: 15px;
  }
  .form-enq {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 15px !important;
    font-family: "f1" !important;
    color: #000 !important;
    border: 0 !important;
    border-bottom: 1px solid #000 !important;
    border-radius: 0 !important;
  }
  .btn-sub {
    background-color: #000 !important;
    color: #fff !important;
  }
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    font-size: 15px !important;
    font-family: "f1" !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
    border-radius: 0 !important;
  }
  /* enquiry form ends  */
  
  /* footer starts */
  .fo_oter {
    height: 100%;
    background-color: #0b1232;
  }
  .lo_go img {
    width: 220px;
    border-radius: 5px;
  }
  .li-st ul {
    list-style-type: none;
    padding: 0;
  }
  .li-st ul li {
    margin-bottom: 10px;
  }
  .li-st a {
    text-decoration: none;
    color: #fff !important;
    font-size: 15px;
    font-family: "f2";
  }
  .gen_ral i {
    color: white;
  }
  .gen_ral p {
    color: #fff;
    font-family: "f2";
    font-size: 13px;
  }
  .fol_low p {
    color: #fff;
    font-family: "f2";
    font-size: 15px;
    margin-bottom: 0px;
  }
  .ge_t .bt_btn {
    background-color: #fff;
    color: #000;
    border-radius: 2px;
    margin-top: 13px;
  
    font-family: "f2";
    font-size: 15px;
  }
  .co_py p {
    color: white;
    font-family: "f2";
    font-size: 14px;
    margin: 0;
    border-top: 1px solid #fff;
    padding: 5px;
  }
  .co_py a {
    color: white;
  }
  
  /* footer ends */
  
  /* digital partner starts */
  .pro_ser p {
    font-family: "f2";
    font-size: 14px;
    text-align: justify;
    margin-left: 50px;
  }
  .dig_img {
    background: #000;
    height: 395px;
    overflow: hidden;
  }
  .dig_img img {
    width: 100%;
    opacity: 0.2;
    min-height: 395px;
  }
  .digi_pt {
    position: relative;
    text-align: center;
  }
  .digi_con {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .digi_con h1 {
    font-family: "f3";
    font-size: 28px;
    color: white;
  }
  .digi_con p {
    font-family: "f1";
    font-size: 15px;
    color: white;
    /* height: 48px; */
    overflow: hidden;
  }
  /* .digi_pt img{
      width: 100%;
  } */
  /* digital partner ends */
  
  /* about us starts */
  .po_1 img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .po_2 img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .ab_ou h1 {
    font-family: "f3";
    font-size: 28px;
  }
  .ab_ou p {
    font-family: "f2";
    font-size: 15px;
    text-align: justify;
    line-height: 25px;
  }
  .ab_oimg img {
    width: 80%;
  }
  /* about us ends */
  /* vision starts */
  .po_3 img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .po_4 img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .vi_si h1 {
    font-family: "f3";
    font-size: 28px;
  }
  .vi_si p {
    font-family: "f1";
    font-size: 15px;
    /* text-align: justify; */
    line-height: 25px;
  }
  .vis_img img {
    width: 100%;
  }
  /* vision ends */
  
  /* technology use starts */
  
  .tec_n {
    background: #dadada !important;
    width: 100%;
    height: 100%;
  }
  .tec_n h1 {
    font-family: "f3";
    font-size: 28px;
    color: #000;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .te_ch p {
    font-family: "f2";
    font-size: 18px;
    margin-top: 10px;
    color: #000;
  }
  .ic_on1 {
    height: 119px;
    width: 119px;
    border-radius: 100%;
    padding: 21px;
    background: #ffe3ca;
    margin: auto;
  }
  .ic_on1 img {
    width: 100%;
  }
  .ic_on2 {
    height: 119px;
    width: 119px;
    border-radius: 100%;
    padding: 21px;
    background: #c5e8ff;
    margin: auto;
  }
  .ic_on2 img {
    width: 100%;
  }
  .ic_on3 {
    height: 119px;
    width: 119px;
    border-radius: 100%;
    padding: 21px;
    background: #ffc5c5;
    margin: auto;
  }
  .ic_on3 img {
    width: 100%;
  }
  .ic_on4 {
    height: 119px;
    width: 119px;
    border-radius: 100%;
    padding: 21px;
    background: #c7fff4;
    margin: auto;
  }
  .ic_on4 img {
    width: 100%;
  }
  .ic_on5 {
    height: 119px;
    width: 119px;
    border-radius: 100%;
    padding: 21px;
    background: #d4c2ff;
    margin: auto;
  }
  .ic_on5 img {
    width: 100%;
  }
  /* technology use ends */
  
  /* why zercorp starts */
  
  .wh_yzer h1 {
    font-family: "f3";
    font-size: 28px;
  }
  .wh_yzer p {
    font-family: "f2";
    font-size: 15px;
  }
  .id_eas {
    height: 72px;
    width: 72px;
    border-radius: 100%;
    padding: 8px;
    background: #ffe3ca;
  }
  .id_eas img {
    width: 55px;
  }
  .ch_os h6 {
    font-family: "f3";
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 0px !important;
  }
  .cho_1 {
    border: 1px solid black;
    border-radius: 15px;
    padding: 15px;
  }
  .cho_2 {
    border: 1px solid black;
    border-radius: 15px;
    padding: 15px;
  }
  .ex_p {
    height: 72px;
    width: 72px;
    border-radius: 100%;
    padding: 8px;
    background: #ffc5c5;
  }
  .ex_p img {
    width: 55px;
  }
  .cho_3 {
    border: 1px solid black;
    border-radius: 15px;
    padding: 15px;
  }
  .qu_al {
    height: 72px;
    width: 72px;
    border-radius: 100%;
    padding: 8px;
    background: #d4c2ff;
  }
  .qu_al img {
    width: 55px;
  }
  .cho_4 {
    border: 1px solid black;
    border-radius: 15px;
    padding: 15px;
  }
  .de_fi {
    height: 72px;
    width: 72px;
    border-radius: 100%;
    padding: 8px;
    background: #c5e8ff;
  }
  .de_fi img {
    width: 55px;
  }
  .cho_5 {
    border: 1px solid black;
    border-radius: 15px;
    padding: 15px;
  }
  .ha_cus {
    height: 72px;
    width: 72px;
    border-radius: 100%;
    padding: 8px;
    background: #c7fff4;
  }
  .ha_cus img {
    width: 55px;
  }
  .cho_1:hover {
    background: #f80;
  }
  .cho_1:hover h6 {
    color: white;
  }
  .cho_2:hover {
    background: #be0200;
  }
  .cho_2:hover h6 {
    color: white;
  }
  .cho_3:hover {
    background: #5f2ac4;
  }
  .cho_3:hover h6 {
    color: white;
  }
  .cho_4:hover {
    background: #248df6;
  }
  .cho_4:hover h6 {
    color: white;
  }
  .cho_5:hover {
    background: #08b294;
  }
  .cho_5:hover h6 {
    color: white;
  }
  /* why zercorp ends */
  
  /* pricing starts */
  .pri_hea p {
    font-family: "f3" !important;
    font-size: 18px !important;
    color: #113695;
  }
  .pri_con h1 {
    font-family: "f3";
    font-size: 30px;
  }
  .pri_par p {
    font-family: "f1";
    font-size: 16px;
  }
  .bo_x1 {
    width: 350px;
    padding: 30px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 21%);
  }
  .bo_x1:hover {
    background: linear-gradient(to right, #0041ae 0%, #258df6 100%);
  }
  .bo_x1:hover {
    color: #fff;
  }
  .bo_x2 {
    width: 350px;
    padding: 30px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 21%);
  }
  .bo_x2:hover {
    background: linear-gradient(to right, #0041ae 0%, #258df6 100%);
  }
  .bo_x2:hover {
    color: #fff;
  }
  .bo_x3 {
    border-radius: 10px;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 21%);
  }
  .bo_x3:hover {
    background: linear-gradient(to right, #0041ae 0%, #258df6 100%);
  }
  .bo_x3:hover {
    color: #fff;
  }
  .bas_con h6 {
    font-family: "f3";
    font-size: 16px;
    text-align: center;
    padding-top: 20px;
  }
  .bas_con img {
    width: 90px;
  }
  .pro_con h6 {
    font-family: "f3";
    font-size: 16px;
    text-align: center;
    padding-top: 20px;
  }
  .pro_con img {
    width: 90px;
  }
  .adv_con h6 {
    font-family: "f3";
    font-size: 16px;
    text-align: center;
    padding-top: 20px;
  }
  .adv_con img {
    width: 90px;
  }
  .li_stw {
    text-align: left !important;
    padding-left: 10px;
  }
  .li_stw p {
    font-family: "f2";
    font-size: 14px;
    padding-left: 5px;
    margin-bottom: 0;
  }
  .st_btn {
    margin: auto;
    border-radius: 3px;
    padding: 2px;
    background: #000;
  }
  .st_btn a {
    font-family: "f3";
    font-size: 14px;
    text-decoration: none;
    color: #fff !important;
    align-items: center;
    padding: 3px;
    display: flex;
    justify-content: center;
  }
  .pu_btn {
    margin: auto;
    border-radius: 3px;
    padding: 2px;
    background: #439586;
  }
  .pu_btn a {
    font-family: "f3";
    font-size: 14px;
    text-decoration: none;
    color: #fff !important;
    align-items: center;
    padding: 3px;
    display: flex;
    justify-content: center;
  }
  /* pricing ends */
  
  /* career banner starts */
  .car_img img {
    width: 100%;
  }
  .car_con h1 {
    font-family: "f3";
    font-size: 25px;
  }
  .car_con p {
    font-family: "f2";
    text-align: justify;
    font-size: 15px;
  }
  /* career banner ends */
  
  /* career table starts */
  .car_tit h1 {
    font-family: "f3";
    font-size: 23px;
  }
  .jo_b {
    background: #c8e4ff;
    padding: 20px;
    border-radius: 5px;
  }
  .jo_b h3 {
    font-family: "f3";
    font-size: 20px;
    color: #000;
  }
  .jo_b:hover {
    box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 15%);
  }
  .jo_b p {
    font-family: "f2";
    font-size: 14px;
    margin-bottom: 0;
  }
  .jo_b .al_ign {
    margin: 5px 0;
    height: 58px;
    overflow: hidden;
    text-align: justify;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
  }
  .jo_b .ic_on {
    font-size: 30px;
  }
  .ex_pe img {
    width: 20px;
    height: 20px;
  }
  .ex_pe p {
    padding-left: 5px;
  }
  .lo_ca img {
    width: 20px;
    height: 20px;
  }
  .lo_ca p {
    padding-left: 5px;
  }
  .app_ly .bt_btn {
    background: #000;
    color: #fff !important;
    border-radius: 2px;
    margin-top: 10px;
    border: none !important;
  }
  .app_ly a {
    font-family: "f2";
    font-size: 14px;
  }
  /* career table ends */
  
  /* account starts */
  .job_head {
    background: linear-gradient(to right, #0041ae 0%, #258df6 100%);
    padding: 30px 0;
  }
  .job_cont {
    width: 80%;
    margin: 0 auto;
  }
  .job_cont p {
    font-family: "f1";
    font-size: 20px;
    color: #fff;
    margin-bottom: 15px;
  }
  .job_cont h1 {
    font-family: "f3";
    font-size: 28px;
    color: #fff;
  }
  .acc_exe p {
    font-family: "f2";
    font-size: 15px;
    color: #fff;
    margin-bottom: 0;
  }
  .acc_app {
    margin-top: 20px;
  }
  .acc_app button {
    border: 1px solid #fff !important;
    color: #fff;
    border-radius: 2px;
    font-family: "f3";
    font-size: 15px;
    padding: 8px;
    background: none;
  }
  .acc_app:hover button {
    background: #fff;
    color: #248df6 !important;
    border-radius: 2px;
  }
  .job_detail {
    margin-top: 30px;
  }
  .job_detail h3 {
    font-family: "f3";
    font-size: 15px;
  }
  .job_detail p {
    color: #000;
    font-family: "f1";
    font-size: 15px;
  }
  .vi_ewal {
    margin: 30px auto 0;
  }
  .vi_ewal button {
    border: 1px solid #248df6;
    border-radius: 2px;
    font-family: "f3";
    font-size: 15px;
    color: #248df6 !important;
    padding: 8px;
    text-decoration: none;
  }
  .modal-content {
    position: relative;
  }
  .modal-content .bt-sub {
    background: linear-gradient(to right, #0041ae 0%, #258df6 100%);
    text-align: center;
    margin: auto;
    color: #fff !important;
    font-family: "f3";
    font-size: 15px;
    border: none;
  }
  .job-form .modal-content {
    background: #fff;
    border-radius: 10px;
  }
  .job-form .modal-content h4 {
    font-family: "f3";
    font-size: 20px;
    text-align: center;
  }
  .job-form .btn-close {
    position: absolute;
    top: 12px;
    right: 5px;
    padding: 10px;
  }
  
  /* account ends */
  
  /* contact us starts */
  .ge_ts {
    margin-top: 40px;
  }
  .ge_ts h1 {
    font-family: "f3";
    font-size: 28px;
  }
  .ge_ts p {
    font-family: "f2";
    font-size: 14px;
  }
  .ge_tou label {
    font-family: "f3";
    font-size: 15px;
  }
  .ge_tou input {
    font-family: "f2";
    font-size: 15px;
  }
  .box1 .icon {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 1px solid #000;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 25px;
    color: #000;
  }
  .box1 .icon:hover {
    background: #f80;
    color: #fff;
  }
  .box2 .icon {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 1px solid #000;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 25px;
    color: #000;
  }
  .box2 .icon:hover {
    background: #be0200;
    color: #fff;
  }
  a {
    text-decoration: none !important;
  }
  .box3 .icon {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 1px solid #000;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 25px;
    color: #000;
  }
  .box3 .icon:hover {
    background: #248df6;
    color: #fff;
  }
  .box1 p {
    font-family: "f1";
    font-size: 14px;
    text-align: center !important;
  }
  .box2 p {
    font-family: "f1";
    font-size: 14px;
    text-align: center !important;
  }
  .box3 p {
    font-family: "f1";
    font-size: 14px;
    text-align: center !important;
  }
  .w-100 {
    width: 100%;
    margin: auto;
  }
  .ge_fo h3 {
    font-family: "f3";
    font-size: 25px;
    margin-bottom: 20px;
  }
  .ge_fo img {
    width: 100%;
    margin: auto;
  }
  
  /* contact us ends */
  
  /* sign in starts */
  
  .si_tx {
    background: #48a5ce;
    border-radius: 10px;
    padding: 48px;
    width: 387px;
    margin: auto;
  }
  .si_tx h3 {
    font-family: "f3";
    font-size: 20px;
    color: #000;
    text-align: center;
  }
  .us_er {
    margin-top: 20px;
    margin-bottom: 21px;
    border-radius: 5px;
  }
  .pas_wrd {
    border-radius: 5px;
    margin-top: 20px;
  }
  .form-control {
    width: 96%;
    margin: auto;
  }
  .control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
  }
  .sub_ject img {
    width: 100%;
  }
  .rem_ber {
    margin-top: 20px;
  }
  .rem_ber span {
    font-family: "f2";
    float: right;
  }
  .rem_ber a {
    text-decoration: none;
    font-family: "f2";
    font-size: 13px;
    color: #000 !important;
  }
  .sub_ject .btn {
    font-family: "f3";
    font-size: 18px;
    padding: 5px 30px;
  }
  
  .al_re {
    font-family: "f2";
    font-size: 14px;
    margin-top: 20px;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .al_re a {
    text-decoration: none;
    color: #000 !important;
  }
  .sub_ject label {
    font-family: "f2";
    font-size: 15px;
    color: #000;
  }
  .sub_ject input {
    font-family: "f2";
    font-size: 15px;
  }
  .lo_sub button {
    margin-top: 30px;
  }
  
  /* sign in ends */
  
  /* forget starts */
  .fo_gt {
    background: #a3c9dce3;
    border-radius: 10px;
    padding: 48px;
    width: 387px;
    margin: auto;
  }
  .fo_gt h3 {
    font-family: "f3";
    font-size: 20px;
    color: #000;
    text-align: center;
  }
  .fo_em {
    margin-top: 35px;
    margin-bottom: 21px;
    border-radius: 5px;
  }
  .fo_sub {
    margin-top: 35px;
  }
  /* forget ends */
  
  /* signup starts */
  .sign_up img {
    width: 70%;
  }
  .na_me label {
    margin-top: 20px;
    border-radius: 5px;
    font-family: "f2";
    font-size: 15px;
    color: #000;
  }
  .na_me input {
    font-family: "f2";
    font-size: 15px;
  }
  .te_co span {
    font-family: "f3";
    font-size: 12px;
  }
  .te_co label {
    font-family: "f2";
    font-size: 12px;
    margin-top: 20px;
  }
  .sign_up .btn {
    font-family: "f3";
    font-size: 18px;
    padding: 5px 30px;
  }
  /* signup ends */
  
  /* crm starts */
  .cr_head {
    width: 90%;
  }
  
  .cr_head p {
    font-family: "f1";
    font-size: 16px;
    font-weight: 500;
  }
  .lap_img img {
    border-radius: 10px;
    width: 100%;
  }
  .crm_con {
    background: #d7efff;
    padding: 30px;
    margin-top: 40px;
  }
  .crm_con img {
    width: 53px;
    height: 53px;
    margin-top: 20px;
  }

  .ser_1:hover{
    background:#FF8F12;
    /* background-color: #07B1BC; */
    color: rgb(18, 17, 17);

  }
  .ser_1 {
    background: rgb(255, 255, 255);
    min-height: 150px;
    border-radius: 10px;
    padding: 5px;
  }

  .ser_1 p {
    padding:3px;
    margin: 5px auto;
    text-align: center;
    max-width: 140px;
    height: auto;
    overflow-x: hidden;
  }
  .crm_con p {
    font-family: "f3";
    font-size: 13px;
    margin-top: 20px;
  }
  .crm_img img {
    width: 90%;
  }
  .crm_det h1 {
    font-family: "f3";
    font-size: 28px;
  }
  .crm_det p {
    font-family: "f1";
    font-size: 15px;
  }
  .w-80 {
    width: 80%;
  }
  .crm_det button {
    background: #258df6;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-family: "f3";
    font-size: 15px;
    border-radius: 2px;
  }
  .crm_det i {
    padding-left: 5px;
  }
  /* crm ends */
  
  /* clients starts */
  .lov_cus h1 {
    font-family: "f3";
    font-size: 30px;
  }
  .lov_cus p {
    font-family: "f1";
    font-size: 15px;
  }
  .rev_1 {
    background: #ffe3ca;
    border-radius: 10px;
    padding: 15px;
    height: 310px;
    margin: auto;
  }
  .rev_2 {
    background: #c5e8ff;
    border-radius: 10px;
    padding: 15px;
    height: 310px;
    margin: auto;
  }
  .rev_3 {
    background: #d4c2ff;
    border-radius: 10px;
    padding: 15px;
    height: 310px;
    margin: auto;
  }
  .rev_1 .text-end {
    font-family: "f3";
  }
  .rev_2 .text-end {
    font-family: "f3";
  }
  .rev_3 .text-end {
    font-family: "f3";
  }
  .cir_img {
    width: 65px;
    height: 65px;
    border-radius: 100%;
    margin: 0 auto 20px;
  }
  .clie_se p {
    font-size: 15px;
    overflow: hidden;
    text-align: justify;
    height: 137px;
  }
  /* clients ends */
  
  .dropdown .btn-drop {
    background: none;
    color: #000;
    font-family: "f2";
    font-size: 14px;
    border: none;
  }
  .dropdown .btn-drop:hover {
    color: #fff;
    background: #258df6;
  }
  .dr_li select {
    font-family: "f2";
    font-size: 14px;
  }
  .vr_txt h1 {
    font-family: "f3";
    font-size: 28px;
    text-align: center;
  }
  .vr_txt p {
    font-family: "f1";
    font-size: 15px;
    text-align: justify;
  }
  .pro_ser img {
    width: 50px;
  }
  .pro_ser h3 {
    font-family: "f3";
    font-size: 15px;
    margin-top: 10px;
  }
  .e_com:hover {
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 21%);
  }
  .game_dev:hover {
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 21%);
  }
  .thr_d:hover {
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 21%);
  }
  .vir_360:hover {
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 21%);
  }
  .form_bg {
    background: #daedff;
    padding: 50px;
    border-radius: 10px;
  }
  .form-ser {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 15px !important;
    font-family: "f1" !important;
    color: #000 !important;
    border-radius: 0 !important;
    border: 0 !important;
  }
.is-active{
  background: #258df6;
  color: #FFF;
}
   .signin_page{
    position: absolute;
    top:20px;
    left:50px;
    width:150px;
    height:65px;
  } 

   .signin_page img{
    object-fit: contain;
    width:100%
  }

/* .crm_enquiry{
  height: 80%;
} */
.enq_img img{
  /* height: 71vh; */
  width:80%

}


.banerss{
  background-color: #dcdce2;
  padding: 10px;
  border-radius: 15px;
}
.seonsess img {
  /* height: 71vh; */
  width:80%

}


  /* .hidden_para_job { display: none;}
.readmore_para_job { margin: 0 5px;} */