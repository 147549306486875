@media (min-width: 1368px) and (max-width: 2550px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .cont_ent p {
    font-size: 16px;
  }
}
@media (min-width: 568px) and (max-width: 767px) {
}
@media (min-width: 320px) and (max-width: 567px) {
  /* .cont_ent h1{
        font-size: 18px;
    }
    .cont_ent p{
        font-size: 9px;
    }
    .ban_img img{
        width: 200px;
    }
    .cont_ent a {
        font-size: 9px;
    } */
}
@media (max-width: 567px) {
  .ban_img img {
    width: 100% !important;
  }
  .cont_ent a {
    font-size: 9px !important;
  }
  .digi_con h1 {
    font-size: 17px !important;
  }
  .digi_con p {
    font-size: 13px;
    height: 36px;
  }

  .cho_1 {
    border: 1px solid black !important;
    border-radius: 15px !important;
    padding: 15px !important;
    width: 241px !important;
  }
  .cho_2 {
    border: 1px solid black !important;
    border-radius: 15px !important;
    padding: 15px !important;
    width: 241px !important;
  }
  .cho_3 {
    border: 1px solid black !important;
    border-radius: 15px !important;
    padding: 15px !important;
    width: 241px !important;
  }
  .cho_4 {
    border: 1px solid black !important;
    border-radius: 15px !important;
    padding: 15px !important;
    width: 241px !important;
  }
  .cho_5 {
    border: 1px solid black !important;
    border-radius: 15px !important;
    padding: 15px !important;
    width: 241px !important;
  }
  .wh_us {
    align-items: center !important;
  }
  .sub_ject label {
    font-family: "f2";
    font-size: 13px;
    color: #000;
  }
  .is-active{
    background:transparent;
    color: #FFF;
  }
}
@media (max-width: 991px) {
  .digi_con h1 {
    font-size: 23px;
  }
  .nav_me img {
    width: 150px;
  }
}
@media (max-width: 320px) {
  .ban_img img {
    width: 130px;
  }
  .cont_ent a {
    font-size: 5px;
  }
}
@media (max-width: 767px) {
  .wh_at {
    height: auto;
    padding: 18px;
  }

  .wh_us {
    justify-content: center;
  }
  .cho_4 {
    border: 1px solid black;
    border-radius: 15px;
    padding: 15px;
    width: 410px;
  }
  .cho_5 {
    border: 1px solid black;
    border-radius: 15px;
    padding: 15px;
    width: 410px;
  }
  .zer_ab {
    justify-content: center;
  }
  .zer_vis {
    justify-content: center;
  }
  .nav-bar .nav_a li a {
    font-family: "f2";
    font-size: 13px;
  }

  .con_img img {
    margin: auto;
  }
  .ser_1 {
    background: rgb(255, 255, 255);
    width: 150px;
    height: 150px;
    min-width: 150px;
    min-height: 150px;
    border-radius: 10px;
    padding: 5px;
  }
  .ser_1 p {
    padding:3px;
    margin: 5px auto;
    text-align: center;
    max-width: 140px;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .navbar-light img {
    width: 150px;
  }

  .ban_img img {
    width: 290px;
  }
  .cont_ent a {
    font-size: 11px;
  }
  .min-nav {
    position: absolute;
    background: #fdf1ee;
    top: 100px;
    right: 0px;
    width: 220px;
    padding: 10px;
    z-index: 1111;
    color: white;
  }

  .navbar-light .navbar-nav .nav-link {
    padding-left: 13px;
    margin-top: 2px;
  }
  .navbar-light .navbar-nav .nav-link.active {
    background: #fff;
    padding-left: 13px !important;
    transition: 0.2s all linear;
    color: #000 !important;
    width: 100%;
    border-left: 2px solid #258df6;
  }
  .navbar-light .navbar-nav .nav-link:hover {
    background: #fff;
    padding-left: 13px !important;
    transition: 0.2s all linear;
    color: #000 !important;
    width: 100%;
    margin-bottom: 10px;
    border-left: 2px solid #258df6;
  }
  .ou_rs2 {
    margin-top: 0;
  }
  .si_tx {
    margin-top: 30px;
  }
}
@media (max-width: 450px) {
  .si_tx {
    background: #a3c9dce3;
    border-radius: 10px;
    padding: 15px;
    width: 340px;
    width: 100%;
    margin-top: 30px;
  }
  .si_gn {
    background: #a3c9dce3;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    margin-top: 30px;
  }
}

@media (max-width: 576px) {
  .col_one {
    height: 250px;
  }
}
